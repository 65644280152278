import * as React from 'react';
import s from './OfferingTagLine.st.css';
import { MultilineTextEllipsis } from '../../MultilineTextEllipsis/MultilineTextEllipsis';

export const OfferingTagLine = ({ children, ...rest }) => {
  return (
    <MultilineTextEllipsis maxLines={2} {...s('root', {}, rest)}>
      {children}
    </MultilineTextEllipsis>
  );
};
