import * as React from 'react';
import s from './OfferingName.st.css';
import { MultilineTextEllipsis } from '../../MultilineTextEllipsis/MultilineTextEllipsis';

export const OfferingName = ({ onClick, children, ...rest }) => {
  return (
    <div onClick={onClick}>
      <MultilineTextEllipsis
        maxLines={2}
        {...s('root', {}, rest)}
        typography="smallTitle"
      >
        {children}
      </MultilineTextEllipsis>
    </div>
  );
};
