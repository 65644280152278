import {
  component,
  ComponentNoSentryWithAppSettingData,
} from './widgetApp.exports';

//file has to be js
export default {
  component,
  ComponentNoSentryWithAppSettingData,
};
