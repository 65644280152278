import * as React from 'react';
import { WidgetApp } from './WidgetApp';
import { withStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import { withSentryErrorBoundary } from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import { SENTRY_BOOKINGS_WIDGET_DSN } from './platform/bookings.const';
import { overideSiteDate } from '../SharedAppKeys/override-site-data/override-site-data';

export const ComponentNoSentry = withStyles(WidgetApp, {
  cssPath: 'widgetComponent.stylable.bundle.css',
});

export const ComponentNoSentryWithAppSettingData = props => {
  const defaultOverrideData = {};
  const { siteTextPresets, siteColors } = props.style;

  for (const key in props.settingsDefaultData) {
    const defaultData = props.settingsDefaultData[key];

    defaultOverrideData[key] = overideSiteDate(
      defaultData,
      siteTextPresets,
      siteColors,
    );
  }

  const settingsData = {
    ...props.settingsDefaultData,
    ...defaultOverrideData,
    ...props.settingsUserData,
  };

  props.style.styleParams = {
    colors: settingsData,
    booleans: settingsData,
    fonts: settingsData,
    numbers: settingsData,
  };

  const newProps = {
    ...props,
    settingsData,
  };
  return <ComponentNoSentry {...newProps} />;
};

export const component = withSentryErrorBoundary(
  ComponentNoSentryWithAppSettingData,
  {
    dsn: SENTRY_BOOKINGS_WIDGET_DSN,
    config: {
      environment: 'ooi-component',
    },
  },
);
