import * as React from 'react';
import { OfferingImage } from '../../OfferingImage/OfferingImage';
import { ImageViewModel } from '../../../domain/offering-view-model-factory';
import s from './OfferingViewImage.st.css';

interface OfferingViewImageProps {
  imageViewModel: ImageViewModel;
  width: number;
  height: number;
  onClick: any;
  forwardedRef: any;
}

class OfferingViewImage extends React.PureComponent<OfferingViewImageProps> {
  render() {
    const { imageViewModel, width, height, onClick, forwardedRef } = this.props;

    return (
      <div
        data-hook="offering-view-image"
        ref={forwardedRef}
        onClick={onClick}
        className={s.root}
      >
        <OfferingImage
          width={width}
          height={height}
          imageResize={imageViewModel.imageResize}
          mediaItem={imageViewModel.mediaItem}
          focalPoint={imageViewModel.focalPoint}
        />
      </div>
    );
  }
}

export default OfferingViewImage;
