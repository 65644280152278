import * as React from 'react';
import { OfferingDomain } from '../../domain/offering-domain';
import { OfferingListWidgetDisplayOptions } from '../../business-logic/offering-list-widget-display-options';
import { OfferingViewModelFactory } from '../../domain/offering-view-model-factory';
import { translate, TransProps } from 'react-i18next';
import OfferingView from '../OfferingView/OfferingView';
import s from './MultiOfferings.st.css';
import { MultiOfferingsTitle } from './MultiOfferingsTitle/MultiOfferingsTitle';
import { MultiOfferingsViewModelFactory } from '../../domain/multi-offerings-view-model-factory';
import { CategoryList } from './CategoryList/CategoryList';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain/dist/src/offerings/offering.dto';

interface MultiOfferingsProps extends TransProps {
  offeringsDomain: OfferingDomain[];
  displayOptions: OfferingListWidgetDisplayOptions;
  categories: OfferingCategoryDto[];
  isDummyMode: boolean;
}

interface MultiOfferingsState {
  selectedCategoryIndex: number;
}

export const OfferingList = props => {
  const { t, displayOptions, offeringsDomain } = props;
  return offeringsDomain.map((offeringDomain, index) => {
    const offeringViewModel = OfferingViewModelFactory.createOfferingViewModel(
      offeringDomain,
      displayOptions.offeringDisplayOptions,
      t,
    );
    return (
      <OfferingView
        offeringViewModel={offeringViewModel}
        key={`offering-${index}`}
      />
    );
  });
};

class MultiOfferingsComponent extends React.PureComponent<
  MultiOfferingsProps,
  MultiOfferingsState
> {
  constructor(props) {
    super(props);
    this.onCategorySelected = this.onCategorySelected.bind(this);
    this.state = {
      selectedCategoryIndex: 0,
    };
  }

  filterOfferingsByCategory() {
    const { offeringsDomain, categories } = this.props;
    const categoryId = categories[this.state.selectedCategoryIndex].id;
    return offeringsDomain.filter(
      offeringDomain => offeringDomain.categoryId === categoryId,
    );
  }

  onCategorySelected(selectedCategoryIndex) {
    this.setState({
      selectedCategoryIndex,
    });
  }

  shouldFilterOfferings(isCategoriesVisible, isDummyMode) {
    return isCategoriesVisible && !isDummyMode;
  }

  render() {
    const {
      t,
      displayOptions,
      categories,
      offeringsDomain,
      isDummyMode,
    } = this.props;
    const multiOfferingsViewModel = MultiOfferingsViewModelFactory.createMultiOfferingsViewModel(
      displayOptions.multiOfferingsDisplayOptions,
      categories,
      t,
    );

    const offeringsToDisplay = this.shouldFilterOfferings(
      multiOfferingsViewModel.categories.isVisible,
      isDummyMode,
    )
      ? this.filterOfferingsByCategory()
      : offeringsDomain;

    return (
      <div
        data-hook="multi-offerings-container"
        {...s('root', { layout: multiOfferingsViewModel.layout })}
      >
        {multiOfferingsViewModel.title.isVisible && (
          <MultiOfferingsTitle
            alignment={multiOfferingsViewModel.title.alignment}
            data-hook="multi-offerings-title"
          >
            {multiOfferingsViewModel.title.text}
          </MultiOfferingsTitle>
        )}

        <div className={s.categoriesContainer}>
          {multiOfferingsViewModel.categories.isVisible && (
            <CategoryList
              categories={categories}
              selectedCategoryIndex={this.state.selectedCategoryIndex}
              categoriesViewModel={multiOfferingsViewModel.categories}
              onCategorySelected={this.onCategorySelected}
            />
          )}
        </div>
        <div className={s.cardsContainer}>
          <OfferingList
            displayOptions={displayOptions}
            offeringsDomain={offeringsToDisplay}
            t={t}
          />
        </div>
      </div>
    );
  }
}

export const MultiOfferings = translate(null, { wait: true })(
  MultiOfferingsComponent,
);
