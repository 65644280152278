import * as React from 'react';
import OfferingViewImage from './OfferingViewImage/OfferingViewImage';
import OfferingInfo from '../OfferingInfo/OfferingInfo';
import { IOfferingViewModel } from '../../domain/offering-view-model-factory';
import {
  ImagePositionOptions,
  OfferingListLayoutOptions,
} from '../../../SharedAppKeys/SettingsKeys';
import { DEFAULT_IMAGE_CONTAINER } from './OfferingView.const';
import s from './OfferingView.st.css';
import {
  BiLoggerProps,
  withBiLoggerContext,
} from '../../adapters/reporting/bi-logger/bi-logger-context';
import { WIDGET_BI_REFERRAL } from '../../adapters/reporting/bi-logger/bi.const';
import { Card } from 'wix-ui-tpa/Card';
import { OverlappingCard } from 'wix-ui-tpa/OverlappingCard';
import { OfferingIntent } from '../../platform/navigation/navigation.const';
import {
  OfferingCallbacksProps,
  withOfferingCallbacksContext,
} from '../offering-callbacks-context';

interface OfferingViewProps {
  offeringViewModel: IOfferingViewModel;
}

interface OfferingViewState {
  shouldUpdateImageDimensions: boolean;
}

class OfferingView extends React.Component<
  OfferingViewProps & BiLoggerProps & OfferingCallbacksProps,
  OfferingViewState
> {
  public static Image;
  private readonly offeringViewImageRef;
  displayName = 'OfferingView';

  constructor(props) {
    super(props);
    this.offeringViewImageRef = React.createRef();
    this.state = { shouldUpdateImageDimensions: false };
    this.imageClickHandler = this.imageClickHandler.bind(this);
  }

  private get isOverlappingLayout() {
    return (
      this.props.offeringViewModel.layout ===
      OfferingListLayoutOptions.OVERLAPPING
    );
  }

  private get isImageVisible() {
    const { offeringViewModel } = this.props;
    return offeringViewModel.image.isVisible;
  }

  private getCardProps() {
    const { offeringViewModel } = this.props;

    return this.isOverlappingLayout
      ? {
          invertInfoPosition:
            ImagePositionOptions.RIGHT === offeringViewModel.imagePosition,
        }
      : {
          ratio: offeringViewModel.ratio,
          flippedRatio: offeringViewModel.ratioFlipped,
          invertInfoPosition:
            ImagePositionOptions.RIGHT === offeringViewModel.imagePosition,
        };
  }

  private offeringImageDimensions(): { width: number; height: number } {
    if (
      this.offeringViewImageRef.current &&
      this.offeringViewImageRef.current.getClientRects()[0]
    ) {
      const {
        width,
        height,
      } = this.offeringViewImageRef.current.getClientRects()[0];
      return { width, height };
    }
    return DEFAULT_IMAGE_CONTAINER;
  }

  imageClickHandler() {
    this.logViewImageClicked();
    this.onOfferingSelected();
  }

  logViewImageClicked() {
    const { offeringViewModel, biLoggerDriver } = this.props;
    biLoggerDriver.sendWidgetClick(
      offeringViewModel.id,
      offeringViewModel.type,
      WIDGET_BI_REFERRAL.WIDGET_IMAGE,
    );
  }

  onOfferingSelected() {
    const { offeringCallbacks, offeringViewModel } = this.props;
    offeringCallbacks.onOfferingSelected(
      offeringViewModel.id,
      OfferingIntent.SHOW_DETAILS,
    );
  }

  componentDidMount() {
    this.setState({ shouldUpdateImageDimensions: true });
  }

  render() {
    const { offeringViewModel } = this.props;

    const CardComponent = this.isOverlappingLayout ? OverlappingCard : Card;

    return (
      <CardComponent
        data-hook="offering-view"
        media={
          this.isImageVisible ? (
            <OfferingViewImage
              forwardedRef={this.offeringViewImageRef}
              onClick={this.imageClickHandler}
              {...this.offeringImageDimensions()}
              imageViewModel={offeringViewModel.image}
            />
          ) : null
        }
        info={<OfferingInfo offeringViewModel={offeringViewModel} />}
        {...s(this.isOverlappingLayout ? 'overlapping' : 'classic')}
        {...this.getCardProps()}
      />
    );
  }
}

export default withOfferingCallbacksContext(withBiLoggerContext(OfferingView));
