import * as React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import s from './OfferingDetails.st.css';

export const OfferingDetails = ({ children, ...rest }) => {
  return (
    <Text typography="listText" {...s('root', {}, rest)}>
      {children}
    </Text>
  );
};
