import * as React from 'react';
import * as ReactModal from 'react-modal';
import s from './TpaModal.st.css';

interface TpaModalProps {
  isOpen: boolean;
  onRequestClose: Function;
}

export default class TpaModal extends React.PureComponent<TpaModalProps> {
  constructor(props) {
    super(props);
  }

  closeModal() {
    const { onRequestClose } = this.props;
    onRequestClose();
  }

  render() {
    const { isOpen, children } = this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        className={s.root}
        onRequestClose={() => this.closeModal()}
        overlayClassName={s.overlay}
      >
        <div
          className={s.closeButton}
          data-hook="modal-close-button"
          onClick={() => this.closeModal()}
        >
          X
        </div>
        {children}
      </ReactModal>
    );
  }
}
