import * as React from 'react';
import { Button, PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import s from './OfferingCTA.st.css';

export const OfferingCTA = ({
  large,
  secondary,
  theme,
  onClick,
  children,
  ...rest
}) => {
  return (
    <div className={s.buttonWrapper}>
      <Button
        {...s('root', { theme }, rest)}
        size={large ? SIZE.large : SIZE.medium}
        priority={secondary ? PRIORITY.secondary : PRIORITY.primary}
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  );
};
