import * as React from 'react';
import s from './EmptyStateView.st.css';
import { Text } from 'wix-ui-tpa/Text';
import { translate, TransProps } from 'react-i18next';
import { FormFactor } from '../../constants';

interface EmptyStateProps {
  deviceType: FormFactor;
}

class EmptyStateViewComponent extends React.PureComponent<
  EmptyStateProps & TransProps
> {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, deviceType } = this.props;
    const isMobile = deviceType === FormFactor.MOBILE;
    return (
      <div data-hook="empty-state-wrapper" {...s('root', { mobile: isMobile })}>
        <div className={s.container}>
          <Text typography="smallTitle">{t('empty-state.text')}</Text>
        </div>
      </div>
    );
  }
}

export default translate(null, { wait: true })(EmptyStateViewComponent);
