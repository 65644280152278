export const isFont = data => data && !!data.editorKey;
export const overrideSiteFontIfNeeded = (font, siteTextPresets) => {
  if (isFont(font)) {
    const siteFont = Object.values(siteTextPresets).find(
      ({ editorKey }) => editorKey === font.editorKey,
    );
    const overloadedFont = { ...siteFont, ...font };
    return overloadedFont;
  }
  return font;
};
