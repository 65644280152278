import * as React from 'react';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { MultiOfferings } from './components/MultiOfferings/MultiOfferings';
import { OfferingDomain } from './domain/offering-domain';
import {
  getDisplayOptions,
  OfferingListWidgetDisplayOptions,
} from './business-logic/offering-list-widget-display-options';
import { MULTI_OFFERINGS_MIN_WIDTH } from './WidgetApp.const';
import {
  BiLoggerContext,
  BiLoggerContextProvider,
} from './adapters/reporting/bi-logger/bi-logger-context';
import { BiLoggerDriver } from './adapters/reporting/bi-logger/bi-logger-driver';
import { ISantaProps } from '@wix/native-components-infra/dist/es/src/types/types';
import { CatalogOfferingDto } from '@wix/bookings-uou-domain/dist/src';
import EmptyStateView from './components/EmptyStateView/EmptyStateView';
import { FormFactor } from './constants';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain/dist/src/offerings/offering.dto';
import { NavigationDriver } from './platform/navigation/navigation-driver';
import UserMessage from './components/UserMessage/UserMessage';
import {
  OfferingCallbacksContext,
  OfferingCallbacksProvider,
} from './components/offering-callbacks-context';
interface WidgetViewerMainProps extends WidgetAppProps {
  host: ISantaProps;
}

interface WidgetAppProps {
  offerings: CatalogOfferingDto[];
  categories: OfferingCategoryDto[];
  locale: string;
  translations: any;
  settingsData: any;
  setContainerHeight: any;
  setContainerDimensions: any;
  biLoggerDriver: BiLoggerDriver;
  navigationDriver: NavigationDriver;
  canReportLoading: boolean;
  formFactor: FormFactor;
  userMessage: UserMessageDriver;
  isDummyMode: boolean;
}

export class WidgetApp extends React.PureComponent<WidgetViewerMainProps> {
  private isComponentLoaded = false;
  private readonly widgetAppRef;
  private isExecuteOnLoadedRequired = true;

  constructor(props) {
    super(props);
    this.widgetAppRef = React.createRef();
  }

  getRef() {
    return this.widgetAppRef;
  }

  private getMultiOfferingsHeight(): number {
    if (this.getRef().current.getClientRects()[0]) {
      return this.getRef().current.getClientRects()[0].height;
    }
    return 0;
  }

  private updateHeight() {
    const { setContainerHeight } = this.props;
    const { dimensions, viewMode } = this.props.host;

    if (
      dimensions &&
      dimensions.height !== this.getMultiOfferingsHeight() &&
      viewMode.toLowerCase() === 'editor'
    ) {
      setContainerHeight(this.getMultiOfferingsHeight());
    }
  }

  shouldDisplayOfferings() {
    const { offerings } = this.props;
    return offerings.length > 0;
  }

  componentDidUpdate() {
    const { setContainerDimensions, formFactor } = this.props;
    const { dimensions } = this.props.host;
    if (
      formFactor !== FormFactor.MOBILE &&
      dimensions &&
      dimensions.width < MULTI_OFFERINGS_MIN_WIDTH
    ) {
      setContainerDimensions(MULTI_OFFERINGS_MIN_WIDTH, dimensions.height);
    }
    this.updateHeight();
    this.executeOnLoadedCallbacks();
  }

  componentDidMount() {
    this.updateHeight();
    this.isComponentLoaded = true;
    this.executeOnLoadedCallbacks();
  }

  executeOnLoadedCallbacks() {
    const { offerings, biLoggerDriver, canReportLoading } = this.props;
    if (
      canReportLoading &&
      this.isExecuteOnLoadedRequired &&
      this.isComponentLoaded
    ) {
      this.isExecuteOnLoadedRequired = false;
      biLoggerDriver.sendViewerOpened(offerings.length);
    }
  }

  createOfferingsDomainInstance = () => {
    return this.props.offerings.map(OfferingDomain.fromDto);
  };

  render() {
    const {
      locale,
      translations,
      settingsData,
      biLoggerDriver,
      navigationDriver,
      formFactor,
      categories,
      userMessage,
      isDummyMode,
      offerings,
    } = this.props;

    const displayOptions: OfferingListWidgetDisplayOptions = getDisplayOptions(
      settingsData,
    );
    const offeringsDomain = this.createOfferingsDomainInstance();

    const biLoggerContext: BiLoggerContext = {
      biLoggerDriver,
    };

    const context: OfferingCallbacksContext = {
      offeringCallbacks: {
        onOfferingSelected: (offeringId, intent) =>
          navigationDriver.navigateToApp(
            offerings.find(offering => offering.id === offeringId),
            intent,
          ),
      },
    };

    return (
      <div ref={this.widgetAppRef}>
        <I18nextProvider i18n={i18n(locale, translations)}>
          <BiLoggerContextProvider value={biLoggerContext}>
            <OfferingCallbacksProvider value={context}>
              <UserMessage
                isOpen={userMessage.shouldDisplayModal}
                onRequestClose={userMessage.closeModal}
              />
              {this.shouldDisplayOfferings() ? (
                <MultiOfferings
                  offeringsDomain={offeringsDomain}
                  displayOptions={displayOptions}
                  categories={categories}
                  isDummyMode={isDummyMode}
                />
              ) : (
                <EmptyStateView deviceType={formFactor} />
              )}
            </OfferingCallbacksProvider>
          </BiLoggerContextProvider>
        </I18nextProvider>
      </div>
    );
  }
}
