import * as React from 'react';
import s from './OfferingImage.st.css';
import {
  getFillImageUrl,
  getFitImageUrl,
} from '../../adapters/media-gallery/media-gallery-adapter';
import { ImageResizeOptions } from '../../../SharedAppKeys/SettingsKeys';
import { ImageDto } from '@wix/bookings-uou-domain/dist/src';

interface OfferingImageProps {
  mediaItem: ImageDto;
  width: number;
  height: number;
  imageResize: ImageResizeOptions;
  focalPoint: { x: number; y: number };
}

export class OfferingImage extends React.PureComponent<OfferingImageProps> {
  private getImageUrl() {
    const { mediaItem, width, height, imageResize, focalPoint } = this.props;

    if (imageResize === ImageResizeOptions.CROP) {
      return getFillImageUrl(mediaItem, { width, height }, focalPoint);
    }
    return getFitImageUrl(mediaItem, { width, height });
  }

  render() {
    const { mediaItem, imageResize } = this.props;

    if (mediaItem) {
      return (
        <div
          data-hook="image-container"
          style={{ backgroundImage: `url(${this.getImageUrl()})` }}
          {...s('root', { imageResize })}
        />
      );
    }
    return null;
  }
}
