import * as React from 'react';
import TpaModal from '../Modal/TpaModal';
import { Button } from 'wix-ui-tpa/Button';
import { Text } from 'wix-ui-tpa/Text';
import s from './UserMessage.st.css';
import { translate, TransProps } from 'react-i18next';

interface UserMessageProps {
  isOpen: boolean;
  onRequestClose: Function;
}

class UserMessage extends React.PureComponent<UserMessageProps & TransProps> {
  constructor(props) {
    super(props);
  }

  closeModal() {
    const { onRequestClose } = this.props;

    onRequestClose();
  }

  render() {
    const { isOpen, onRequestClose, t } = this.props;

    return (
      <TpaModal isOpen={isOpen} onRequestClose={() => this.closeModal()}>
        <div className={s.root}>
          <Text {...s('message-text')}>
            {t('user-message.not-bookable.line-1')}
            <br />
            {t('user-message.not-bookable.line-2')}
          </Text>
          <Button
            {...s('message-button')}
            onClick={() => onRequestClose()}
            data-hook="user-message-action-button"
          >
            {t('user-message.action-ok-label')}
          </Button>
        </div>
      </TpaModal>
    );
  }
}

export default translate(null, { wait: true })(UserMessage);
