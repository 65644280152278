import * as React from 'react';
import { Text, TextProps } from 'wix-ui-tpa/dist/src/components/Text';

export interface MultilineTextEllipsisProps extends TextProps {
  maxLines: number;
}

export class MultilineTextEllipsis extends React.PureComponent<
  MultilineTextEllipsisProps
> {
  private readonly text;
  private readonly wrapperRef;

  constructor({ children, props }) {
    super(props);
    this.text = children;
    this.wrapperRef = React.createRef();
  }

  isOverflown(e, lineHeight) {
    return lineHeight * this.props.maxLines < e.scrollHeight;
  }

  getLineHeight(element) {
    const innerText = element.innerText;
    element.innerText = `.`;
    const lineHeight = element.offsetHeight;
    element.innerText = innerText;
    return lineHeight;
  }

  initClamping(wrapperElement) {
    const textElement = wrapperElement.firstChild;
    this.clampLines(textElement);
    this.resizeSensor(wrapperElement, () => {
      this.clampLines(textElement);
    });
  }

  clampLines(textElement) {
    const lineHeight = this.getLineHeight(textElement);
    textElement.innerText = this.text;
    if (this.isOverflown(textElement, lineHeight)) {
      let clamped = this.text;
      while (this.isOverflown(textElement, lineHeight)) {
        clamped = clamped.slice(0, -1);
        textElement.innerText = `${clamped}...`;
      }
    }
  }

  resizeSensor(element, callback) {
    let zIndex = parseInt(getComputedStyle(element).zIndex, 10);
    if (isNaN(zIndex)) {
      zIndex = 0;
    }
    zIndex--;

    const expand = document.createElement('div');
    expand.style.position = 'absolute';
    expand.style.left = '0px';
    expand.style.top = '0px';
    expand.style.right = '0px';
    expand.style.bottom = '0px';
    expand.style.overflow = 'hidden';
    expand.style.zIndex = zIndex.toString();
    expand.style.visibility = 'hidden';

    const expandChild = document.createElement('div');
    expandChild.style.position = 'absolute';
    expandChild.style.left = '0px';
    expandChild.style.top = '0px';
    expandChild.style.width = '10000000px';
    expandChild.style.height = '10000000px';
    expand.appendChild(expandChild);

    const shrink = document.createElement('div');
    shrink.style.position = 'absolute';
    shrink.style.left = '0px';
    shrink.style.top = '0px';
    shrink.style.right = '0px';
    shrink.style.bottom = '0px';
    shrink.style.overflow = 'hidden';
    shrink.style.zIndex = zIndex.toString();
    shrink.style.visibility = 'hidden';

    const shrinkChild = document.createElement('div');
    shrinkChild.style.position = 'absolute';
    shrinkChild.style.left = '0px';
    shrinkChild.style.top = '0px';
    shrinkChild.style.width = '200%';
    shrinkChild.style.height = '200%';
    shrink.appendChild(shrinkChild);

    element.appendChild(expand);
    element.appendChild(shrink);

    function setScroll() {
      expand.scrollLeft = 10000000;
      expand.scrollTop = 10000000;

      shrink.scrollLeft = 10000000;
      shrink.scrollTop = 10000000;
    }

    setScroll();

    const size = element.getBoundingClientRect();

    let currentWidth = size.width;
    let currentHeight = size.height;

    const onScroll = function() {
      const elementSize = element.getBoundingClientRect();

      const newWidth = elementSize.width;
      const newHeight = elementSize.height;

      if (newWidth !== currentWidth || newHeight !== currentHeight) {
        currentWidth = newWidth;
        currentHeight = newHeight;

        callback();
      }

      setScroll();
    };

    expand.addEventListener('scroll', onScroll);
    shrink.addEventListener('scroll', onScroll);
  }

  componentDidMount() {
    this.initClamping(this.wrapperRef.current);
  }

  render() {
    return (
      <div ref={this.wrapperRef}>
        <Text data-hook="multiline-ellipsis-text" {...this.props} />
      </div>
    );
  }
}
